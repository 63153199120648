import request from '@/utils/request'


// 查询置顶订单列表
export function listUserCar(query) {
  return request({
    url: '/user/user-operating-areas/list',
    method: 'get',
    params: query
  })
}

// 查询置顶订单分页
export function pageUserCar(query) {
  return request({
    url: '/user/user-operating-areas/page',
    method: 'get',
    params: query
  })
}

// 查询置顶订单详细
export function getUserCar(data) {
  return request({
    url: '/user/user-operating-areas/detail',
    method: 'get',
    params: data
  })
}

// 新增置顶订单
export function addUserCar(data) {
  return request({
    url: '/user/user-operating-areas/add',
    method: 'post',
    data: data
  })
}

// 修改置顶订单
export function updateUserCar(data) {
  return request({
    url: '/user/user-operating-areas/edit',
    method: 'post',
    data: data
  })
}

// 删除置顶订单
export function delUserCar(data) {
  return request({
    url: '/user/user-operating-areas/delete',
    method: 'post',
    data: data
  })
}
export function approve(data) {
  return request({
    url: '/user/user-operating-areas/approve',
    method: 'post',
    data: data
  })
}

export function approveAll(data) {
  return request({
    url: '/user/user-operating-areas/approveAll',
    method: 'post',
    data: data
  })
}
